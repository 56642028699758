export const quai = {
    id: 9,
    name: 'Quai',
    nativeCurrency: { name: 'Quai', symbol: 'QUAI', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://node.quaiswap.io'],
        },
    },
    blockExplorers: {
        default: {
            name: 'QuaiScan',
            url: 'https://quaiscan.io',
            apiUrl: 'https://api.quaiscan.io/api',
        },
    },
    contracts: {
        multicall3: {
            address: '0x00567637197E6554e2CF47a3988Cb7B819f4E92C',
            blockCreated: 1,
        },
    },
};