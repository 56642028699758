/* eslint-disable no-unused-vars */
import React, { useEffect, useState, memo } from 'react';
// import Datafeed from './datafeed';
import Datafeed_v2 from './datafeed';

const Chart = (props) => {
  const { symbol, pair, pairForFirst, pairForSecond, isTokenFirst, interval } = props;

  useEffect(() => {
    if (symbol && interval) {
      // eslint-disable-next-line no-undef
      const widget = (window.tvWidget = new TradingView.widget({
        symbol: symbol,
        interval: interval,
        fullscreen: false,
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        container_id: 'tv_chart_container',
        datafeed: Datafeed_v2(pair, isTokenFirst, pairForFirst, pairForSecond),
        library_path: '/charting_library/',
        toolbar_bg: '#0b1217',
        overrides: {
          'paneProperties.rightMargin': 0,
          'paneProperties.background': '#0b1217',
          'paneProperties.backgroundType': 'gradient',
          'paneProperties.backgroundGradientEndColor': '#0b1217',
          'paneProperties.backgroundGradientStartColor': '#0b1217',
          'mainSeriesProperties.candleStyle.upColor': '#089981', // Up Candle Color
          'mainSeriesProperties.candleStyle.downColor': '#f23645', // Down Candle Color
          'mainSeriesProperties.candleStyle.borderUpColor': '#089981', // Up Candle Border Color
          'mainSeriesProperties.candleStyle.borderDownColor': '#f23645', // Down Candle Border Color
          'mainSeriesProperties.candleStyle.drawBorder': false, // Disable candle borders
          'mainSeriesProperties.minTick': '100000000,1,false',
          "scalesProperties.textSize": 18,
          "scalesProperties.showLeftScale": false,
        },
        loading_screen: {
          backgroundColor: "#fff",
        },
        disabled_features: ['header_symbol_search'],
        time_frames: [],
        theme: 'Dark',
      }));

      widget.onChartReady(async () => {
        widget.activeChart().setTimezone('UTC');
      });
    }
  }, [symbol, interval, pairForFirst, pairForSecond]);
  return (
    <div id="tv_chart_container" className='h-full !rounded-md' />
  );
};

export default Chart;