export const ChartStatus = {
    notStable: 0,
    isFirstToken: 1,
    isSecondToken: 2,
    notPair:3
}

export const tokenTemp = {
    "symbol": "",
    "img": "",
    "name": "",
    "address": "",
    "decimals": 18,
    "pumpfun": false
}
export const pairStable =
{
    "QUAIUSDT": "",
    "QSWAPUSDT": "",
}
export const tokenList = {
    "QUAI": {
        "symbol": "QUAI",
        "img": "/assets/quai.png",
        "name": "Wrapped QUAI",
        "address": "0x006C3e2AaAE5DB1bCd11A1a097cE572312EADdBB",
        "decimals": 18,
        "pumpfun": false
    },
    "QSWAP": {
        "symbol": "QSWAP",
        "img": "/assets/quaiswap.svg",
        "name": "QuaiSwap",
        "address": "0x001cA38127d832693b1F3aD208966053160A7463",
        "decimals": 18,
        "pumpfun": false
    },
    "POOP": {
        "symbol": "POOP",
        "img": "/assets/poop_logo_rotating_css.gif",
        "name": "Poop.fun",
        "address": "0x006a59D2228F0a3586Dd34E40751C1aC8B41ca20",
        "decimals": 18,
        "pumpfun": false
    },
    "QIQI": {
        "symbol": "QIQI",
        "img": "/assets/qiqi.png",
        "name": "QIQI",
        "address": "0x00357df7c4DbdfAeb345d066dE860E860B8AE1E0",
        "decimals": 18,
        "pumpfun": false
    },
    "SOON": {
        "symbol": "SOON",
        "img": "/assets/soon.png",
        "name": "SOON",
        "address": "0x004C7926967b899ea69E871A366a5B344660f7EB",
    },
    "FLY": {
        "symbol": "FLY",
        "img": "/assets/fly.jpg",
        "name": "FLY",
        "address": "0x0040198bD115CBC1ce165327B26af1a6d30fAc3E",
    }
}

export const Pairs = {
    "QSWAP/QUAI": "0x003489Bb4c0e0E598694cDBb19E7e78AA25aACbA"
}

export const dataTemp = {
    icon: '',
    image: '',
    core: false,
    earned: 0,
    apr: '',
    liquidity: '',
    multiplier: '',
    contractUrl: "#",
    getUrl: "#",
    pairInfoUrl: "#",
    cakeEarned: 0,
    name: '',
    lpTokenAddress: 0x0000000000000000000000000000000000000000
}
export const LpTemp = {
    token0: {
        "symbol": "",
        "img": "",
        "name": "",
        "address": "",
        "decimals": 18,
        "pumpfun": false
    },
    token1: {
        "symbol": "",
        "img": "",
        "name": "",
        "address": "",
        "decimals": 18,
        "pumpfun": false
    },
    balance: "",
    address: ""
}

export const initialToken = {
    address: '',
    symbol: '',
    decimals: 0,
    name: '',
    img: '',
    pumpfun: false,
};
export const masterchefAddress = "0x001a937A454B71E6285B09645501634118dc573E"; // Uniswap V2 Masterchef contract address
export const stakingAddress = "0x001c2fB39B253f30Dd4B21825809aB3190425fe6"; // Uniswap V2 Masterchef contract address
export const WETH = "0x006C3e2AaAE5DB1bCd11A1a097cE572312EADdBB"; // WETH token address
export const factoryAddress = "0x0006112e89ee10615273ED72FE035cC068BC57A9"; // Uniswap V2 Factory contract address
export const routerAddress = "0x006432Ea8c46cBF981f6e710d2439C941CeBe2d0"; //Uniswap v2 Router contract address
export const Zero = '0x0000000000000000000000000000000000000000';
export const QswapTokenAddress = "0x001cA38127d832693b1F3aD208966053160A7463";
export const CurveAddress = '0x0005A0b0dC1FF95154Cd37E2c35B6695422f9F45';

export const QswapTokenDecimals = 18;
export const lpTokenDecimals = 18;
