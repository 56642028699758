const Config = {
  API_URL: 'https://poop.fun/backend', // TODO
  SOCKET_URL: 'https://backend.poop.fun', // TODO
  GRAPHQL_URL: 'https://poop.fun/subgraphs/name/quaifun', // TODO
  pumpLogoURL: 'https://backend.poop.fun/logos/',
  Subgraph_URL: 'https://node.quaiswap.io/subgraphs/name/quaiswap',
  // backend_HTTP_URL: 'https://node.quaiswap.io/getPrices',
  backend_HTTP_URL: 'https://node.quaiswap.io/getPrices',
  backend_WS_URL: 'wss://node.quaiswap.io/websocket',
  backend_HTTP_URL_STABLE: 'https://node.quaiswap.io/getRates',
  airdrop_URL: 'https://node.quaiswap.io/airdrop',
};

export default Config