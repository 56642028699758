import axios from "../../util/axios";
import { ChartStatus } from "../../constant/constant";
import Config from "../../util/config";

const resValues = {
    // minutes
    1: 1,
    5: 5,
    15: 15,
    30: 30,
    45: 45,
    60: 60,
    120: 120,
    240: 240,
    "1D": 1440,
    "1W": 10080,
    "1M": 70560
};

export const configurationData = {
    supported_resolutions: ["1", "5", "15", "30", "45", "60", "120", "240", "D", "W", "M"],
};

function convertTimestamp(timestamp) {
    var d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
        yyyy = d.getFullYear(),
        mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
        dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
        hh = d.getHours(),
        h = hh,
        min = ("0" + d.getMinutes()).slice(-2), // Add leading 0.
        ampm = "AM",
        time;

    if (hh > 12) {
        h = hh - 12;
        ampm = "PM";
    } else if (hh === 12) {
        h = 12;
        ampm = "PM";
    } else if (hh === 0) {
        h = 12;
    }
    time = yyyy + "-" + mm + "-" + dd + ", " + h + ":" + min + " " + ampm;
    return time;
}

let Socket = new WebSocket(Config.backend_WS_URL)
Socket.onopen = () => {
}
Socket.onclose = () => {
}

const channelToSubscription = new Map();
const datafeed = (pair, isTokenFirst, pairForFirst, pairForSecond) => {
    let latestBar;
    return {
        onReady: (callback) => {
            // console.log('[Test]onReady-setTimout')
            setTimeout(() => callback(configurationData));
        },
        searchSymbols: async () => {
            // console.log('[Test]searchSymbol')
        },
        resolveSymbol: async (
            symbolName,
            onSymbolResolvedCallback,
            onResolveErrorCallback
        ) => {
            // console.log('[Test]resolveSymbol')
            let symbolInfo = {
                name: symbolName,
                has_intraday: true,
                has_no_volume: false,
                session: "24x7",
                timezone: "Europe/Athens",
                exchange: "QuaiSwap",
                minmov: 0.00000001,
                pricescale: 100000000,
                has_weekly_and_monthly: true,
                volume_precision: 8,
                data_status: "streaming",
                supported_resolutions: configurationData.supported_resolutions,
            };
            onSymbolResolvedCallback(symbolInfo);
        },

        getBars: async (
            symbolInfo,
            resolution,
            periodParams,
            onHistoryCallback,
            onErrorCallback,
            // firstDataRequest
        ) => {
            const { from, to, firstDataRequest } = periodParams;
            const resVal = resValues[resolution];

            try {
                let response;
                let priceData;
                let priceDTO;

                if (pair !== undefined && pair !== "0x0000000000000000000000000000000000000000") {
                    priceDTO = {
                        pair: pair,
                        from: from,
                        to: to,
                        interval: resVal,
                        firstRequest: firstDataRequest
                    }

                    priceData = await axios.post(Config.backend_HTTP_URL, priceDTO)
                } else {
                    console.log('pair', pairForFirst, pairForSecond)
                    priceDTO = {
                        pair0: pairForFirst,
                        pair1: pairForSecond,
                        from: from,
                        to: to,
                        interval: resVal,
                        firstRequest: firstDataRequest
                    }

                    priceData = await axios.post(Config.backend_HTTP_URL_STABLE, priceDTO)
                }

                if (priceData.status !== 200) {
                    onHistoryCallback([], { noData: false });
                    return;
                }

                response = priceData.data.data.data

                if (!response.length) {
                    onHistoryCallback([], { noData: true })
                }

                if (response && response != undefined && response.length > 0) {
                    // console.log('[Nightfury-response]', response)
                    let bars = response.map((data) => {
                        let date = new Date(data.time)
                        return {
                            time: date.getTime(),
                            low: isTokenFirst === ChartStatus.isSecondToken ? data.low : (data.low === 0 ? 0 : Number(1 / data.low)),
                            high: isTokenFirst === ChartStatus.isSecondToken ? data.high : (data.high === 0 ? 0 : Number(1 / data.high)),
                            open: isTokenFirst === ChartStatus.isSecondToken ? data.open : (data.open === 0 ? 0 : Number(1 / data.open)),
                            close: isTokenFirst === ChartStatus.isSecondToken ? data.close : (data.close === 0 ? 0 : Number(1 / data.close)),
                            volume: data.volumeUsd,
                        };
                    });
                    bars = bars.sort(function (a, b) {
                        if (a.time < b.time) return -1;
                        else if (a.time > b.time) return 1;
                        return 0;
                    });

                    if (bars.length > 0)
                        latestBar = bars[bars.length - 1];
                    window.delta = 0;
                    // console.log('getBar-latestBar', latestBar)
                    onHistoryCallback(bars, { noData: false });
                }


            }
            catch (error) {
                onErrorCallback(error);
            }
        },
        subscribeBars: (
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscribeUID,
            onResetCacheNeededCallback,
            lastDailyBar
        ) => {
            try {
                const handler = {
                    id: subscribeUID,
                    callback: onRealtimeCallback,
                };
                let subscriptionItem = channelToSubscription.get(pair);
                if (subscriptionItem) {
                    // Already subscribed to the channel, use the existing subscription
                    subscriptionItem.handlers.push(handler);
                    return;
                }

                subscriptionItem = {
                    subscribeUID,
                    resolution,
                    lastDailyBar: lastDailyBar || latestBar,
                    handlers: [handler],
                };
                channelToSubscription.set(pair, subscriptionItem);
                Socket.onmessage = (event) => {
                    console.log('onMessage')
                    const res = JSON.parse(event.data)
                    if (res && res.event == "PRICE_DATA") {

                        const subscriptionItem = channelToSubscription.get(res.data.pair);
                        console.log("subscriptionItem: ", subscriptionItem)
                        if (subscriptionItem === undefined) {
                            return;
                        }
                        const lastDailyBar = subscriptionItem.lastDailyBar;
                        const resValInMin = resValues[subscriptionItem.resolution];
                        const resPeriod = resValInMin * 60 * 1000;

                        // const nextIntervalTime = parseInt(lastDailyBar.time / resPeriod + 1) * resPeriod;
                        const nextIntervalTime = lastDailyBar.time + resPeriod;
                        // console.log('====== PRICE_CHART ========', res.data)
                        const time = new Date(res.data.time)
                        const timestamp = time.getTime()
                        const price = isTokenFirst === ChartStatus.isSecondToken ? res.data.price : (res.data.price === 0 ? 0 : Number(1 / res.data.price));

                        let bar;
                        if (timestamp >= nextIntervalTime) {
                            bar = {
                                time: nextIntervalTime + Math.floor((timestamp - nextIntervalTime) / resPeriod) * resPeriod,
                                open: lastDailyBar.close,
                                high: Math.max(lastDailyBar.close, price),
                                low: Math.min(lastDailyBar.close, price),
                                close: price,
                                volume: Number(res.data.volumeUsd)
                            };
                            console.log("[socket] Generate new bar", bar);
                        } else {
                            bar = {
                                ...lastDailyBar,
                                high: Math.max(lastDailyBar.high, price),
                                low: Math.min(lastDailyBar.low, price),
                                close: price,
                                volume: lastDailyBar.volume + Number(res.data.volumeUsd)
                            };
                            console.log("[socket] Update the latest bar by price", bar);
                        }
                        // console.log("latestBar: ", latestBar)
                        if (latestBar != undefined) {
                            if (latestBar.time < bar.time) bar.open = latestBar.close;
                            else bar.open = latestBar.open;
                        }
                        latestBar = bar;
                        // console.log("subscriptionItem.lastDailyBar: start: ", subscriptionItem.lastDailyBar)
                        subscriptionItem.lastDailyBar = bar;
                        // console.log("subscriptionItem.lastDailyBar: end: ", subscriptionItem.lastDailyBar)

                        // Send data to every subscriber of that symbol
                        subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
                    }
                };
            }
            catch (e) {
                console.log(e)
            }
        },
        unsubscribeBars: (subscribeUID) => {
            const resolution = subscribeUID.split("_").pop();
            const resVal = resValues[resolution]
            // console.log('unsubscribe', resVal, resolution)
            // if (Socket.readyState === WebSocket.OPEN)
            //     Socket.send(JSON.stringify({
            //         event: "UNSUBSCRIBE_PRICE_CHART",
            //         data: {
            //             subId: subscribeUID,
            //             pairAddress: pair,
            //             interval: resVal,
            //             timestamp: timestamp.get(subscribeUID)
            //         }
            //     }))
            // console.log('Websocket disconnected😒', pair, subscribeUID, timestamp.get(subscribeUID), resVal)

        },
    };
};
export default datafeed;